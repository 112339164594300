<template>
    <div>
        <div ref="button"></div>
    </div>
</template>
<script>
import LoginMixin from "@Platon/components/login/LoginMixin"

export default {
    name: "GoogleProvider",
    mixins: [LoginMixin],

    data() {
        return {
            googleLoginLoaded: false
        }
    },

    mounted() {
        this.initGoogleLogin(this.$refs.button)
    }
}
</script>
<style lang="scss">
.btn-google {
    margin: 0;
    padding: 0;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;

    .google-content {
        height: 40px;
        display: flex;
        cursor: pointer;
        align-items: center;

        .logo {
            padding-left: 15px;
        }

        svg {
            width: 24px;
            height: 24px;
        }

        p {
            margin: 0;
            width: 100%;
            line-height: 1;
            letter-spacing: 0.21px;
            text-align: center;
            font-weight: 500;
            font-family: "Roboto", sans-serif;
        }
    }
}

.btn-google {
    width: 100%;
    padding-top: 1.5px;
    background: #fff;
    display: inline-block;

    &:hover {
    }

    &:active {
    }

    .google-content p {
        color: #757575;
    }
}
</style>
